import { AnalyticsUser, Box, InformationDialog, useUser } from "@encompaas/common";
import { useGetAnalyticsUserQuery } from "@encompaas/common/services";
import { useEffect, useState, useCallback } from "react";
import { adminBaseUrl, getLogiEndpoint } from '../utils/AnalyticsHelper';

const REFRESH_INTERVAL = 120000;

const AdminPage = () => {
    const user = useUser(true) as AnalyticsUser;
    const [isLoading, setLoading] = useState(true);
    const [isScriptLoading, setScriptLoading] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const _errorTitle = "We've encountered an error.";
    const [errorValue, setErrorValue] = useState("");
    const [adminURL, setAdminURL] = useState("");

    const { data: analyticsUserData, refetch: refetchAnalyticsUser } = useGetAnalyticsUserQuery(user);

    const updateAdminURL = useCallback((token: string) => {
        const newUrl = `${adminBaseUrl}${atob(token)}`;
        setAdminURL(newUrl);
        setLoading(false);
    }, []);

    const refreshToken = useCallback(async () => {
        try {
            const result = await refetchAnalyticsUser();
            if (result.data && result.data.access_token !== user.access_token) {
                updateAdminURL(result.data.access_token);
            }
        } catch (error) {
            console.error("Failed to refresh token:", error);
            setErrorValue("Failed to refresh authentication. Please try logging in again.");
            setErrorDialogOpen(true);
        }
    }, [user, refetchAnalyticsUser, updateAdminURL]);

    // Initial setup and token refresh
    useEffect(() => {
        if (analyticsUserData && analyticsUserData.access_token) {
            updateAdminURL(analyticsUserData.access_token);
        } else if (user && user.access_token) {
            updateAdminURL(user.access_token);
        }

        const intervalId = setInterval(refreshToken, REFRESH_INTERVAL);
        return () => clearInterval(intervalId);
    }, [user, analyticsUserData, updateAdminURL, refreshToken]);

    // Script loading logic
    useEffect(() => {
        const loadScripts = async () => {
            if (document.getElementById("composer-embed-manager")) return;

            setScriptLoading(true);

            // Take the script src and id (optional) and append it to the document head
            const loadScript = (src: string, id?: string) => {
                return new Promise<void>((resolve, reject) => {
                    const script = document.createElement("script");
                    script.src = src;
                    if (id) script.id = id;
                    script.onload = () => resolve();
                    script.onerror = reject;
                    document.head.appendChild(script);
                });
            };

            try {
                // Try add scripts to dom with id if required
                await loadScript(`${getLogiEndpoint()}embed/embed.js`, "composer-embed-manager");
                await loadScript(`${getLogiEndpoint()}api/branding/customJs.js`);

                window.addEventListener("load", async () => {
                    // @ts-ignore
                    window.replaceIcons();
                });

                setScriptLoading(false);
            } catch (error) {
                console.error("Failed to load scripts:", error);
                setErrorValue("Failed to load necessary scripts. Please refresh the page.");
                setErrorDialogOpen(true);
                setScriptLoading(false);
            }
        };

        loadScripts();
    }, []);

    if (isLoading || isScriptLoading) {
        return <div></div>;
    }
    else {
        return (
            <Box>
                <iframe
                    id="AnalyticsAdminIFrame"
                    width="fit-content"
                    height="100%"
                    style={{ border: "none" }}
                    src={adminURL}
                    title="Analytics Admin"
                    className="ecs-box ecs-background-light ecs-rounded-top ecs-box-column ecs-padding-none ecs-gap-none ecs-border-none ecs-border-color-dark ecs-box-blur"
                ></iframe>
                <InformationDialog
                    open={errorDialogOpen}
                    onClose={() => setErrorDialogOpen(false)}
                    title={_errorTitle}
                    variant={'error'}
                    information={errorValue}
                />
            </Box>
        );
    }
}

export default AdminPage;