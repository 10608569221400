import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { useUser } from '@encompaas/common/hooks';
import AdminPage from './AdminPage';
import PageWithAppBar from '../components/PageWithAppBar';

const HomePage = () => {
    const user = useUser();
    return (
        <PageWithAppBar data-testid={TEST_CONSTANTS.HOME_PAGE}>
                <AdminPage></AdminPage>
        </PageWithAppBar>
    );
};

export default HomePage;
