import { config } from "@encompaas/common/config";

export const logiEndpoint = config.REACT_APP_ENC_LOGI_URL;
export const baseURL = config.REACT_APP_ENC_LOGI_URL;
const enwebEndpoint = config.API_BASE_URL;
const tokenRefreshEndpoint = `${enwebEndpoint}User/GetAnalyticsUserDetails`;
export const adminBaseUrl = `${baseURL}admin.html?access_token=`;
export let hasLoaded = false;

export function getTokenRefreshEndpoint() {
    return tokenRefreshEndpoint;
}

export function getLogiEndpoint() {
    return logiEndpoint;
}
